@import '../../../styles/customMediaQueries.css';

.container {
  margin: -15px 24px 15px 24px;
  @media (--viewportLarge) {
    margin: -15px 0 15px 0;
  }
}

.containerNonPanel {
  margin: unset;
}

.message {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: -2px;
}

.popupButton {
  color: var(--marketplaceColor);
  border: none;
  cursor: pointer;
  text-align: left;
  width: 100%;
  font-size: 14px;
  text-decoration: underline;
}

.phoneIcon {
  margin-left: -5px;
  margin-right: 5px;
  font-size: 14px;
}
