/* File: font.css */
@font-face {
  font-family: 'NORD';
  src: local('NORD'), url('../fonts/NORD/Nord-Regular.woff') format('woff');
}

@font-face {
  font-family: 'OtenticSignature';
  src: local('OtenticSignature'), url('../fonts/OtenticSignature/Otentic.ttf') format('woff');
}

@font-face {
  font-family: 'GTAmericaMonoRegular';
  src: local('GTAmericaMonoRegular'),
    url('../fonts/GTAmericaMonoRegular/GT-America-Mono-Regular.woff') format('woff');
}

@font-face {
  font-family: 'GTAmericaMonoExtra';
  src: local('GTAmericaMonoExtra'),
    url('../fonts/GTAmericaMonoExtra/GT-America-Mono-Bold.woff') format('woff'),
    url('../fonts/GTAmericaMonoExtra/GT-America-Mono-Regular-Italic.woff') format('woff'),
    url('../fonts/GTAmericaMonoExtra/GT-America-Mono-Bold-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('../fonts/Roboto/Roboto-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Gantari';
  src: local('Gantari'), url('../fonts/Gantari/Gantari-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'HelloRadio';
  src: local('HelloRadio'), url('../fonts/HelloRadio/HelloRadio.otf') format('ttf');

}

@font-face {
  font-family: 'RetroVoice';
  src: url('../fonts/RetroVoice/RetroVoice-ExtrBoldExpEx2.ttf') format('ttf');
}

@font-face {
  font-family: 'Poppins';
  src: url('../fonts/Poppins/Poppins-Bold.ttf') format('ttf');
}